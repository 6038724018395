import React, { useState, useEffect } from "react";
import { TextField, Button, Grid } from '@material-ui/core';
import {useRecoilValue} from 'recoil';
import {userState} from './state'
import { makeStyles } from '@material-ui/core/styles';


function Model() {

    const [iphone78plus1x, setiphone78plus1x] = useState(0);
    const [iphone78plus2x, setiphone78plus2x] = useState(0);
    const [iphone78plus4x, setiphone78plus4x] = useState(0);
    const [iphonex1x, setiphonex1x] = useState(0);
    const [iphonex2x, setiphonex2x] = useState(0);
    const [iphonex4x, setiphonex4x] = useState(0);
    const [iphonexs1x, setiphonexs1x] = useState(0);
    const [iphonexs2x, setiphonexs2x] = useState(0);
    const [iphonexs4x, setiphonexs4x] = useState(0);
    const [iphone11base1x, setiphone11base1x] = useState(0);
    const [iphone11base2x, setiphone11base2x] = useState(0);
    const [iphone11base4x, setiphone11base4x] = useState(0);
    const [iphone11pro1x, setiphone11pro1x] = useState(0);
    const [iphone11pro2x, setiphone11pro2x] = useState(0);
    const [iphone11pro4x, setiphone11pro4x] = useState(0);
    const [iphone11promax1x, setiphone11promax1x] = useState(0);
    const [iphone11promax2x, setiphone11promax2x] = useState(0);
    const [iphone11promax4x, setiphone11promax4x] = useState(0);
    const [iphone12mini1x, setiphone12mini1x] = useState(0);
    const [iphone12mini2x, setiphone12mini2x] = useState(0);
    const [iphone12mini4x, setiphone12mini4x] = useState(0);
    const [iphone12pro1x, setiphone12pro1x] = useState(0);
    const [iphone12pro2x, setiphone12pro2x] = useState(0);
    const [iphone12pro4x, setiphone12pro4x] = useState(0);
    const [iphone12promax1x, setiphone12promax1x] = useState(0);
    const [iphone12promax2x, setiphone12promax2x] = useState(0);
    const [iphone12promax4x, setiphone12promax4x] = useState(0);
    const [iphone13base1x, setiphone13base1x] = useState(0);
    const [iphone13base2x, setiphone13base2x] = useState(0);
    const [iphone13base4x, setiphone13base4x] = useState(0);
    const [iphone13mini1x, setiphone13mini1x] = useState(0);
    const [iphone13mini2x, setiphone13mini2x] = useState(0);
    const [iphone13mini4x, setiphone13mini4x] = useState(0);
    const [iphone13pro1x, setiphone13pro1x] = useState(0);
    const [iphone13pro2x, setiphone13pro2x] = useState(0);
    const [iphone13pro4x, setiphone13pro4x] = useState(0);
    const [iphone13promax1x, setiphone13promax1x] = useState(0);
    const [iphone13promax2x, setiphone13promax2x] = useState(0);
    const [iphone13promax4x, setiphone13promax4x] = useState(0);
    const [iphonedefault1x, setiphonedefault1x] = useState(0);
    const [iphonedefault2x, setiphonedefault2x] = useState(0);
    const [iphonedefault4x, setiphonedefault4x] = useState(0);

    const [currentParams, setCurrentParams] = useState('');

    const user = useRecoilValue(userState);

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        fetchParams();
    }, [] );
    
    function fetchParams() {
        // fetch("http://localhost:8000/params",  {
        fetch('/api/params', {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + user['token']
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            setCurrentParams(data)
            
            setiphone78plus1x(data['iphone78plus1x']);
            setiphone78plus2x(data['iphone78plus2x']);
            setiphone78plus4x(data['iphone78plus4x']);
            setiphonex1x(data['iphonex1x']);
            setiphonex2x(data['iphonex2x']);
            setiphonex4x(data['iphonex4x']);
            setiphonexs1x(data['iphonexs1x']);
            setiphonexs2x(data['iphonexs2x']);
            setiphonexs4x(data['iphonexs4x']);
            setiphone11base1x(data['iphone11base1x']);
            setiphone11base2x(data['iphone11base2x']);
            setiphone11base4x(data['iphone11base4x']);
            setiphone11pro1x(data['iphone11pro1x']);
            setiphone11pro2x(data['iphone11pro2x']);
            setiphone11pro4x(data['iphone11pro4x']);
            setiphone11promax1x(data['iphone11promax1x']);
            setiphone11promax2x(data['iphone11promax2x']);
            setiphone11promax4x(data['iphone11promax4x']);
            setiphone12mini1x(data['iphone12mini1x']);
            setiphone12mini2x(data['iphone12mini2x']);
            setiphone12mini4x(data['iphone12mini4x']);
            setiphone12pro1x(data['iphone12pro1x']);
            setiphone12pro2x(data['iphone12pro2x']);
            setiphone12pro4x(data['iphone12pro4x']);
            setiphone12promax1x(data['iphone12promax1x']);
            setiphone12promax2x(data['iphone12promax2x']);
            setiphone12promax4x(data['iphone12promax4x']);
            setiphone13base1x(data['iphone13base1x']);
            setiphone13base2x(data['iphone13base2x']);
            setiphone13base4x(data['iphone13base4x']);
            setiphone13mini1x(data['iphone13mini1x']);
            setiphone13mini2x(data['iphone13mini2x']);
            setiphone13mini4x(data['iphone13mini4x']);
            setiphone13pro1x(data['iphone13pro1x']);
            setiphone13pro2x(data['iphone13pro2x']);
            setiphone13pro4x(data['iphone13pro4x']);
            setiphone13promax1x(data['iphone13promax1x']);
            setiphone13promax2x(data['iphone13promax2x']);
            setiphone13promax4x(data['iphone13promax4x']);
            setiphonedefault1x(data['iphonedefault1x']);
            setiphonedefault2x(data['iphonedefault2x']);
            setiphonedefault4x(data['iphonedefault4x']);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    function storeData(){
        console.log('store data')
        var dataDict = {
            iphone78plus1x: iphone78plus1x,
            iphone78plus2x: iphone78plus2x,
            iphone78plus4x: iphone78plus4x,
            iphonex1x: iphonex1x,
            iphonex2x: iphonex2x,
            iphonex4x: iphonex4x,
            iphonexs1x: iphonexs1x,
            iphonexs2x: iphonexs2x,
            iphonexs4x: iphonexs4x,
            iphone11base1x: iphone11base1x,
            iphone11base2x: iphone11base2x,
            iphone11base4x: iphone11base4x,
            iphone11pro1x: iphone11pro1x,
            iphone11pro2x: iphone11pro2x,
            iphone11pro4x: iphone11pro4x,
            iphone11promax1x: iphone11promax1x,
            iphone11promax2x: iphone11promax2x,
            iphone11promax4x: iphone11promax4x,
            iphone12mini1x: iphone12mini1x,
            iphone12mini2x: iphone12mini2x,
            iphone12mini4x: iphone12mini4x,
            iphone12pro1x: iphone12pro1x,
            iphone12pro2x: iphone12pro2x,
            iphone12pro4x: iphone12pro4x,
            iphone12promax1x: iphone12promax1x,
            iphone12promax2x: iphone12promax2x,
            iphone12promax4x: iphone12promax4x,
            iphone13base1x: iphone13base1x,
            iphone13base2x: iphone13base2x,
            iphone13base4x: iphone13base4x,
            iphone13mini1x: iphone13mini1x,
            iphone13mini2x: iphone13mini2x,
            iphone13mini4x: iphone13mini4x,
            iphone13pro1x: iphone13pro1x,
            iphone13pro2x: iphone13pro2x,
            iphone13pro4x: iphone13pro4x,
            iphone13promax1x: iphone13promax1x,
            iphone13promax2x: iphone13promax2x,
            iphone13promax4x: iphone13promax4x,
            iphonedefault1x: iphonedefault1x,
            iphonedefault2x: iphonedefault2x,
            iphonedefault4x: iphonedefault4x
        }

        // fetch("http://localhost:8000/store",  {
        fetch('/api/store', {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataDict) // body data type must match "Content-Type" header
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            fetchParams();          
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    const useStyles = makeStyles((theme) => ({
        root: {
          '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
          },
        },
      }));
      
    const classes = useStyles();

    return (
        <Grid container direction="row" justify="center" alignItems="center" >        
   
        <form className={classes.root} noValidate autoComplete="off">
        <div>
          <TextField id="iphone78plus1x" value={iphone78plus1x} onChange={(e) => setiphone78plus1x(e.target.value)} label="iPhone 7,8 Plus - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone78plus2x" value={iphone78plus2x} onChange={(e) => setiphone78plus2x(e.target.value)} label="iPhone 7,8 Plus - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone78plus4x" value={iphone78plus4x} onChange={(e) => setiphone78plus4x(e.target.value)} label="iPhone 7,8 Plus - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphonex1x" value={iphonex1x} onChange={(e) => setiphonex1x(e.target.value)} label="iPhoneX - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphonex2x" value={iphonex2x} onChange={(e) => setiphonex2x(e.target.value)} label="iPhoneX - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphonex4x" value={iphonex4x} onChange={(e) => setiphonex4x(e.target.value)} label="iPhoneX - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphonexs1x" value={iphonexs1x} onChange={(e) => setiphonexs1x(e.target.value)} label="iPhoneXS - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphonexs2x" value={iphonexs2x} onChange={(e) => setiphonexs2x(e.target.value)} label="iPhoneXS - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphonexs4x" value={iphonexs4x} onChange={(e) => setiphonexs4x(e.target.value)} label="iPhoneXS - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphone11base1x" value={iphone11base1x} onChange={(e) => setiphone11base1x(e.target.value)} label="iPhone11Base - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone11base2x" value={iphone11base2x} onChange={(e) => setiphone11base2x(e.target.value)} label="iPhone11Base - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone11base4x" value={iphone11base4x} onChange={(e) => setiphone11base4x(e.target.value)} label="iPhone11Base - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphone11pro1x" value={iphone11pro1x} onChange={(e) => setiphone11pro1x(e.target.value)} label="iPhone11Pro - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone11pro2x" value={iphone11pro2x} onChange={(e) => setiphone11pro2x(e.target.value)} label="iPhone11Pro - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone11pro4x" value={iphone11pro4x} onChange={(e) => setiphone11pro4x(e.target.value)} label="iPhone11Pro - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphone11promax1x" value={iphone11promax1x} onChange={(e) => setiphone11promax1x(e.target.value)} label="iPhone11ProMax - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone11promax2x" value={iphone11promax2x} onChange={(e) => setiphone11promax2x(e.target.value)} label="iPhone11ProMax - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone11promax4x" value={iphone11promax4x} onChange={(e) => setiphone11promax4x(e.target.value)} label="iPhone11ProMax - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphone12mini1x" value={iphone12mini1x} onChange={(e) => setiphone12mini1x(e.target.value)} label="iPhone12Mini - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone12mini2x" value={iphone12mini2x} onChange={(e) => setiphone12mini2x(e.target.value)} label="iPhone12Mini - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone12mini4x" value={iphone12mini4x} onChange={(e) => setiphone12mini4x(e.target.value)} label="iPhone12Mini - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphone12pro1x" value={iphone12pro1x} onChange={(e) => setiphone12pro1x(e.target.value)} label="iPhone12Pro - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone12pro2x" value={iphone12pro2x} onChange={(e) => setiphone12pro2x(e.target.value)} label="iPhone12Pro - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone12pro4x" value={iphone12pro4x} onChange={(e) => setiphone12pro4x(e.target.value)} label="iPhone12Pro - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphone12promax1x" value={iphone12promax1x} onChange={(e) => setiphone12promax1x(e.target.value)} label="iPhone12ProMax - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone12promax2x" value={iphone12promax2x} onChange={(e) => setiphone12promax2x(e.target.value)} label="iPhone12ProMax - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone12promax4x" value={iphone12promax4x} onChange={(e) => setiphone12promax4x(e.target.value)} label="iPhone12ProMax - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphone13base1x" value={iphone13base1x} onChange={(e) => setiphone13base1x(e.target.value)} label="iPhone13Base - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone13base2x" value={iphone13base2x} onChange={(e) => setiphone13base2x(e.target.value)} label="iPhone13Base - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone13base4x" value={iphone13base4x} onChange={(e) => setiphone13base4x(e.target.value)} label="iPhone13Base - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphone13mini1x" value={iphone13mini1x} onChange={(e) => setiphone13mini1x(e.target.value)} label="iPhone13Mini - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone13mini2x" value={iphone13mini2x} onChange={(e) => setiphone13mini2x(e.target.value)} label="iPhone13Mini - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone13mini4x" value={iphone13mini4x} onChange={(e) => setiphone13mini4x(e.target.value)} label="iPhone13Mini - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphone13pro1x" value={iphone13pro1x} onChange={(e) => setiphone13pro1x(e.target.value)} label="iPhone13Pro - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone13pro2x" value={iphone13pro2x} onChange={(e) => setiphone13pro2x(e.target.value)} label="iPhone13Pro - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone13pro4x" value={iphone13pro4x} onChange={(e) => setiphone13pro4x(e.target.value)} label="iPhone13Pro - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphone13promax1x" value={iphone13promax1x} onChange={(e) => setiphone13promax1x(e.target.value)} label="iPhone13ProMax - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone13promax2x" value={iphone13promax2x} onChange={(e) => setiphone13promax2x(e.target.value)} label="iPhone13ProMax - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphone13promax4x" value={iphone13promax4x} onChange={(e) => setiphone13promax4x(e.target.value)} label="iPhone13ProMax - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
          <TextField id="iphonedefault1x" value={iphonedefault1x} onChange={(e) => setiphonedefault1x(e.target.value)} label="default - 1x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphonedefault2x" value={iphonedefault2x} onChange={(e) => setiphonedefault2x(e.target.value)} label="default - 2x" type="number" InputLabelProps={{ shrink: true,}}/>
          <TextField id="iphonedefault4x" value={iphonedefault4x} onChange={(e) => setiphonedefault4x(e.target.value)} label="default - 4x" type="number" InputLabelProps={{ shrink: true,}}/>
        </div>
        <div>
        <Button 
            // disabled={!validateForm()} 
            onClick={storeData}><h3>Save</h3>
        </Button>
        {/* <Button 
            // disabled={!validateForm()} 
            onClick={fetchParams}>Load current settings
        </Button> */}
        </div>
        <div>
            {/* <p>{Object.entries(currentParams).map( ([key, value]) => {return(<p>{key} : {value}</p>);} )}</p>       */}
        </div>
      </form>  
        {/* <TextField type='number' placeholder="Age" inputProps={{ 'aria-label': 'description' }} onChange={(e) => setAge(e.target.value)}/> */}
           
    </Grid>
    );
}

export default Model;

// function validateForm() {
//     // return Age > 0 && Age < 125;
// }

// function sendRequest() {
//     // fetch('/api/model?item_id='+Age, {
//     //     method: 'GET',
//     //     headers: {
//     //       'Authorization': 'Bearer ' + user['token']
//     //     }
//     // })
//     // .then(response => response.json())
//     // .then(data => {
//     //     console.log('Success:', data);
//     //     setModelScore(data['item_id']); 
//     //     console.log(modelScore)          
//     // })
//     // .catch((error) => {
//     //     console.error('Error:', error);
//     // });
// }


// function handleSubmit(event) {
//     event.preventDefault();
//     console.log(iphone78plus1x)

//     // sendRequest();
//     // console.log(Age)
// }